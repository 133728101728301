<template>
  <div class="row align-center space-center absolute-box" v-if="dialogContent">
    <section class="absolute-cont">
      <div class="row space-between align-center dialog-top">
        <p class="row align-center dialog-title">
          <span></span
          >{{
            stockOrding.needCreate == 1 && !InventoryStatus
              ? '申请盘点'
              : stockOrding.needCreate == 1
              ? '新建盘点单'
              : stockOrding.needCreate != 3
              ? '添加明细'
              : '提示'
          }}
        </p>
      </div>
      <template v-if="stockOrding.needCreate == 1 && InventoryStatus">
        <!--                <div class="dialog-tips">-->
        <!--                    <div>{{tipsTitle}}</div>-->
        <!--                    <p v-for="(item, index) in tipsList" :key="index">{{item}}</p>-->
        <!--                </div>-->
        <!--                <div class="dialog-create">-->
        <!--                    <el-form size="small" label-width="80px" label-position="right" :model="form" :rules="rules">-->
        <!--                      <el-form-item label="盘点名称" prop="stockName">-->
        <!--                        <el-input placeholder="请输入盘点单名称" ref="elInput" v-model="form.stockName"></el-input>-->
        <!--                      </el-form-item>-->
        <!--                      <el-form-item label="盘点类型" prop="stockType">-->
        <!--                        <el-radio-group v-model="form.stockType" @input="changeOption">-->
        <!--                          <el-radio v-for="item in stockTypeList" :key="item.id" :label="item.id" >{{item.label}}</el-radio>-->
        <!--                        </el-radio-group>-->
        <!--                      </el-form-item>-->
        <!--                      <template v-if="form.stockType !== '1'">-->
        <!--                        <el-form-item :label="stockTypeList[form.stockType - 1].label + '分类'" prop="classifyType">-->
        <!--                          <el-cascader-->
        <!--                            style="width: 100%;"-->
        <!--                            placeholder="请选择分类"-->
        <!--                            v-model="form.classifyType"-->
        <!--                            clearable-->
        <!--                            collapse-tags-->
        <!--                            filterable-->
        <!--                            :key="form.stockType"-->
        <!--                            :options="options"-->
        <!--                            :show-all-levels="false"-->
        <!--                            :props="{-->
        <!--                              multiple: true,-->
        <!--                              checkStrictly: false,-->
        <!--                              emitPath: false,-->
        <!--                              value: 'id',-->
        <!--                              label: 'name',-->
        <!--                              children: 'pojoList',-->
        <!--                            }">-->
        <!--                          </el-cascader>-->
        <!--                        </el-form-item>-->
        <!--                      </template>-->
        <!--                    </el-form>-->
        <!--                    <div class="cursor dialog-btn" @click="creatStocking">新建盘点单</div>-->
        <!--                </div>-->
        <div
          style="
            text-align: center;
            height: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          ">
          <img style="height: 100px; width: 100px" src="@/assets/images/alone.png" />
          <div style="color: #717171; font-size: 20px; margin-top: 10px">等待后台创建盘点单</div>
        </div>
      </template>
      <template v-if="!InventoryStatus && stockOrding.needCreate == 1">
        <div
          v-loading="inventoryLoading"
          style="
            text-align: center;
            height: 300px;
            display: flex;
            padding: 20px;
            flex-direction: column;
            justify-content: space-between;
            align-items: space-between;
          ">
          <div class="inventory-box" v-if="!inventoryOK">
            <span><span style="color: red">*</span> 盘点时间：</span>
            <el-date-picker
              style="width: 76%"
              v-model="inventoryTime"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              :picker-options="pickerOptions"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="inventory-box" v-if="!inventoryOK">
            <span><span style="color: red">*</span> 盘点说明：</span>
            <el-input type="textarea" style="width: 76%" :rows="6.5" placeholder="请输入" v-model="inventoryTextarea">
            </el-input>
          </div>
          <img
            v-if="inventoryOK"
            src="@/assets/images/succese.png"
            style="width: 180px; height: 180px; margin: 0 auto" />
          <div v-if="inventoryOK">您的申请已发送</div>
          <div>
            <el-button
              v-if="inventoryOK"
              type="primary"
              style="width: 80px"
              size="mini"
              @click="
                inventoryOK = false
                inventoryTextarea = ''
                inventoryTime = ''
                queryStocking()
              "
              >确认</el-button
            >
            <el-button v-else type="primary" style="width: 80px" size="mini" @click="inventorySure">确认</el-button>
          </div>
        </div>
      </template>
      <div class="dialog-add" v-if="stockOrding.needCreate == 2">
        <div class="row align-center space-center create-info">
          <img src="@/assets/images/createsucc.png" />
          <div>{{ stockOrding.name }}</div>
        </div>
        <div class="row align-center space-center">
          <div><span style="color: #f8475f">*</span>区域：</div>
          <el-select
            v-model="areaId"
            placeholder="请选择区域"
            size="small"
            disabled
            style="width: 150px"
            @change="changeCurArea">
            <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <el-button
          style="display: flex; justify-content: center; margin: 20px auto 0; width: 120px"
          type="primary"
          :loading="loading"
          @click="addDetail"
          >添加明细</el-button
        >
      </div>
      <div v-if="stockOrding.needCreate == 3" class="tips">
        <p class="iconfont icon-tishi"></p>
        <p>该盘点单已完成<br />不能继续添加明细</p>
        <div @click="$router.push('/stockRecord')">查看盘点记录</div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.inventory-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.absolute-box {
  position: absolute;
  left: 0;
  top: 60px;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;
  .absolute-cont {
    background: #fff;
    border-radius: 6px;
    width: 480px;
    .dialog-top {
      background: #f5f7fa;
      padding: 0 10px;
      border-radius: 6px 6px 0 0;
      font-family: 'font-Medium';
      line-height: 40px;
      .dialog-title {
        span {
          width: 4px;
          height: 20px;
          background: #067cf2;
          margin-right: 6px;
        }
      }
      > span {
        color: #999;
        padding: 10px;
        margin-right: -10px;
        cursor: pointer;
      }
    }
    .dialog-tips {
      font-family: 'font-Regular';
      margin: 10px;
      background: #f5f5f5;
      border-radius: 6px;
      padding: 10px;
      font-size: 14px;
      color: #666;
      > div {
        color: #333;
        font-family: 'font-Medium';
      }
      > p {
        line-height: 30px;
      }
    }
    .dialog-create {
      margin: 0 10px;
      font-family: 'font-Regular';
      .new-item {
        > p {
          margin-bottom: 10px;
        }
      }
    }
    .dialog-btn {
      height: 36px;
      line-height: 36px;
      width: 120px;
      color: #fff;
      background: #067cf2;
      border-radius: 3px;
      margin: 30px auto 20px;
      text-align: center;
    }
    .dialog-add {
      font-family: 'font-Regular';
      padding-bottom: 50px;
      .create-info {
        margin: 30px 0;
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
    }
    .tips {
      font-family: 'font-Light';
      text-align: center;
      margin: 30px auto 0;
      .icon-tishi {
        color: #ff9000;
        font-size: 40px;
      }
      p {
        margin: 20px 0 30px;
        line-height: 30px;
      }
      div {
        background: #eff7ff;
        width: 180px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin: 0 auto 30px;
        border-radius: 36px;
        color: #067cf2;
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
import moment from 'moment'
import {
  queryCurrentDetail,
  createRecordInfo,
  queryWarehouseAreaConfig,
  queryMarkedWords,
  queryGoodsCategoryTree,
  addInventoryRecordId,
  judgmentInventoryApply,
  createInventoryApply
} from '@/libs/http/modules/stock'
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      inventoryLoading: false,
      inventoryOK: false,
      inventoryTime: '',
      inventoryTextarea: '',
      InventoryStatus: false,
      stockOddModule: false,
      stockOrding: {},
      tipsTitle: '',
      tipsList: [],
      areaList: [],
      areaName: '',
      areaId: '',
      dialogContent: false,
      stockTypeList: [
        { label: '全盘', id: '1' },
        { label: '款式', id: '2' },
        { label: '风格', id: '3' },
        { label: '时间', id: '4' }
      ],
      options: [],
      form: {
        stockType: '1',
        classifyType: '',
        stockName: this.$cache.local.get('name') + '创建的' + moment().format('MM') + '月盘点单01'
      },
      rules: {
        stockType: [{ required: true, message: '请选择盘点类型', trigger: 'blur' }],
        classifyType: [{ required: true, message: '请选择盘点类型', trigger: 'blur' }],
        stockName: [{ required: true, message: '请选择盘点区域', trigger: 'blur' }]
      },
      loading: false
    }
  },
  created() {
    this.dialogContent = true
    this.queryStocking()
  },
  methods: {
    inventorySure() {
      if (!this.inventoryTime || !this.inventoryTime.length) {
        return this.$message({ type: 'error', message: '请先选择时间！', duration: 2000, showClose: true })
      }
      if (!this.inventoryTextarea) {
        return this.$message({ type: 'error', message: '请先填写盘点说明！', duration: 2000, showClose: true })
      }
      this.inventoryLoading = true
      createInventoryApply({
        startTime: this.inventoryTime[0],
        endTime: this.inventoryTime[1],
        note: this.inventoryTextarea
      })
        .then(data => {
          this.inventoryLoading = false
          this.inventoryOK = data
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
          this.inventoryLoading = false
        })
    },
    changeOption(e) {
      if (e > 1) {
        this.options = []
        this.form.classifyType = ''
        this.fetchQuerytree(e - 2)
      }
    },
    fetchQuerytree(type) {
      queryGoodsCategoryTree({
        queryType: type
      })
        .then(data => {
          if (data && data.length) {
            this.options = this.recursiveFilter(data)
          }
        })
        .catch(error => {})
    },
    recursiveFilter(arr) {
      const data = arr
      data.forEach(item => {
        if (item.pojoList && item.pojoList.length) {
          this.recursiveFilter(item.pojoList)
        } else {
          delete item.pojoList
        }
      })
      return data
    },
    InventoryFunction() {
      this.inventoryLoading = true
      judgmentInventoryApply()
        .then(data => {
          this.inventoryLoading = false
          this.InventoryStatus = data
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
          this.inventoryLoading = false
        })
    },
    queryStocking() {
      queryCurrentDetail()
        .then(data => {
          this.stockOrding = data
          if (this.stockOrding.needCreate == '1') {
            this.InventoryFunction()
          }
          if (this.$amount.equals(data.needCreate, 1)) {
            this.$nextTick(() => {
              this.$refs.elInput.focus()
            })
            this.fetchTipsList()
          } else {
            this.fetchArea()
          }
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    fetchArea() {
      queryWarehouseAreaConfig()
        .then(data => {
          if (data && data.items) {
            this.areaList = data.items
            this.areaId = data.items[0].id
            this.areaName = data.items[0].name
          } else {
            this.areaList = []
          }
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    fetchTipsList() {
      queryMarkedWords()
        .then(data => {
          this.tipsTitle = data.title
          this.tipsList = data.content
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    creatStocking() {
      if (!this.form.stockName) {
        return this.$message({ type: 'error', message: '请填写盘点单名称', duration: 2000, showClose: true })
      }
      if (this.form.stockType !== '1' && !this.form.classifyType) {
        return this.$message({ type: 'error', message: '请选择分类', duration: 2000, showClose: true })
      }
      let reqData = {
        inventoryName: this.form.stockName,
        type: this.form.stockType === '1' ? '1' : '0'
      }
      if (this.form.classifyType) {
        reqData['categoryIdList'] = this.form.classifyType
      }
      createRecordInfo(reqData)
        .then(data => {
          this.queryStocking()
          this.$message({ type: 'success', message: '盘点单创建成功', duration: 2000, showClose: true })
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    changeCurArea(e) {
      this.areaId = this.areaList[e - 1].id
      this.areaName = this.areaList[e - 1].name
    },
    addDetail() {
      if (!this.areaId || !this.areaName) {
        return this.$message({ type: 'error', message: '请选择区域', duration: 2000, showClose: true })
      }
      this.loading = true
      addInventoryRecordId({
        areaId: this.areaId,
        recordInfoId: this.stockOrding['id']
      })
        .then(res => {
          let areadata = {
            areaId: this.areaId,
            areaName: this.areaName,
            recordDetailId: res.recordDetailId,
            stockOrdingId: this.stockOrding['id']
          }
          this.$emit('sendAreaData', areadata)
          this.dialogContent = false
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
    }
  }
}
</script>
